import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";

import {
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Alert
  } from "@mui/material";
  import { styled } from '@mui/material/styles';

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
function Enquiry(props) {
    const [enquiryData, setEnquiryData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    useEffect(() => {       
        axios.get(process.env.REACT_APP_API_URL+'/api/enquiry' ).then((response) => setEnquiryData(response.data));      
    }, []);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    return (
        <div>
            <TableContainer>
            <Table>
            {/* Table Header */}
            <TableHead>
                <StyledTableRow>
                <StyledTableCell>Email</StyledTableCell>  
                <StyledTableCell>Contact No</StyledTableCell>
                <StyledTableCell>Shop</StyledTableCell>
                <StyledTableCell >Search Details</StyledTableCell>              
                </StyledTableRow>
            </TableHead>
            {/* Table Body */}
            <TableBody>
                {
                  enquiryData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((enquiry) => {
                    var searchField = enquiry.tyre || enquiry.battery || enquiry.accessory || [];                    
                    return(                    
                      <Fragment>
                        <StyledTableRow key={enquiry._id} >
                          <StyledTableCell >{enquiry.email}</StyledTableCell> 
                          <StyledTableCell >{enquiry.contactNo}</StyledTableCell> 
                          <StyledTableCell >{enquiry.shopId ? enquiry.shopId.name: ''}</StyledTableCell> 
                          {
                            Object.keys(searchField).map((value,index) => {
                              return(                                
                                <StyledTableCell>{value.toUpperCase() + ' : ' + searchField[value]}</StyledTableCell>
                                
                              )
                            })
                          }
                        </StyledTableRow>
                       
                      </Fragment>
                    ) 
                  })
                }
            </TableBody>
            </Table>
        </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={enquiryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
        />
        </div>
    )

}

export default Enquiry;