import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert
} from "@mui/material";
import { styled } from '@mui/material/styles';

function Company(props) {
  console.log([props])
  const [companyData, setCompanyData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({
    vehicleCompany: "" 
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    // Fetch data from your API and update 'companyData' state   
    axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/companies' ).then((response) => setCompanyData(response.data));      
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = () => {
    setValidationError(false);    
    setFormData({vehicleCompany: "" });
    setOpenForm(true);
  };

  
  const handleDeleteClick = async (vehicleCompany) => {
    let id = vehicleCompany._id;
    let data = await axios.delete(process.env.REACT_APP_API_URL+'/api/vehicle/company/' + id,{
      headers:{
        "Authorization": props.userData.token
      }
    });
    let response = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/companies');
    setCompanyData(response.data); 
  }
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
   
    try{
      if(formData.vehicleCompany){
        setValidationError(false);
        let data = await axios.post(process.env.REACT_APP_API_URL+'/api/vehicle/company',{...formData},{
          headers:{
            "Authorization": props.userData.token
          }
        });   
        let response = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/companies');
        setCompanyData(response.data);  
        setOpenForm(false);  
      }else{
        setValidationError(true);
      }           
      }catch(e){
        console.log('Error');
      }
    }
  return (
    <div>
      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Company Name</StyledTableCell>  
              <StyledTableCell>Delete</StyledTableCell>              
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {companyData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((company) => (
                <StyledTableRow key={company._id}>
                  <StyledTableCell>{company.vehicleCompany}</StyledTableCell> 
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteClick(company)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={companyData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Company
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>Add New Company</DialogTitle>
        <br/>
        <DialogContent>
          <TextField
            label="Company Name"
            name="company"
            value={formData.vehicleCompany}
            onChange={(e) =>
              setFormData({ ...formData, vehicleCompany: e.target.value })
            }
            fullWidth
          />          
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Company;
