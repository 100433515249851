import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Select,
  Alert,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';

function Accessory(props) {
  console.log([props])
  const [accessoryData, setAccessoryData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [vehicleCompany, setVehicleCompany] = useState([]);
  const [vehicleVariant, setVehicleVariant] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openForm, setOpenForm] = useState(false);
  const [selectedAccessory, setSelectedAccessory] = useState(null);
  const [formData, setFormData] = useState({
    vehicleCompanyId: "",
    variantNameId: "",
    accessoryType: "",    
    accessoryName: "",
    price: "",
    shopId: ""
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    // Fetch data from your API and update 'accessoryData' state
    if(props.userData && props.userData.shopDetails[0])
      var shopId = props.userData?.shopDetails?.[0]._id || '';
    if(shopId){
      setFormData({ ...formData, shopId:shopId})
      axios.get(process.env.REACT_APP_API_URL+'/api/accessories/filter?shopId=' + shopId).then((response) => setAccessoryData(response.data));
      axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/companies').then((response) => setVehicleCompany(response.data));
    }
      
  }, [props.userData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = () => {
    setSelectedAccessory(null);
    setValidationError(false);
    setFormData({
        vehicleCompanyId: "",
        variantNameId: "",
        accessoryType: "",    
        accessoryName: "",
        price: "",
        shopId: ""
      });
    setOpenForm(true);
  };

  const handleEditClick = async (accessory) => {
    setValidationError(false);
    setSelectedAccessory(accessory);
    let variantData = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/variant?vehicleCompanyId=' + accessory.vehicleCompany._id);
    setVehicleVariant(variantData.data);
    setFormData({
        vehicleCompanyId: accessory.vehicleCompany._id,
        variantNameId: accessory.variantName._id,
        accessoryType: accessory.accessoryType,    
        accessoryName: accessory.accessoryName,
        price: accessory.price,
        shopId: accessory.shopId
      });
    setOpenForm(true);
  };
  const handleDeleteClick = async (accessory) => {
    let id = accessory._id;
    let data = await axios.delete(process.env.REACT_APP_API_URL+'/api/accessories/' + id,{
      headers:{
        "Authorization": props.userData.token
      }
    });
    let response = await axios.get(process.env.REACT_APP_API_URL+'/api/accessories/filter?shopId=' + formData.shopId);
    setAccessoryData(response.data); 
  }
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {   
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
    try{
      if(formData.vehicleCompanyId && formData.variantNameId && formData.accessoryType && formData.accessoryName && formData.price ){
        setValidationError(false)
        formData.shopId = props.userData?.shopDetails?.[0]._id || '';
        if(!selectedAccessory){        
          let data = await axios.post(process.env.REACT_APP_API_URL+'/api/accessories/',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });        
        }else{
          formData.accessoryId = selectedAccessory._id;
          let data = await axios.put(process.env.REACT_APP_API_URL+'/api/accessories/',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });    
        }    
        let response = await axios.get(process.env.REACT_APP_API_URL+'/api/accessories/filter?shopId=' + formData.shopId);
        setAccessoryData(response.data);  
        setOpenForm(false);
      }else{
        setValidationError(true)
      }
            
      
    }catch(e){
      console.log('Error');
    }

    
  };

  const onCompanyChange = async (e) => {    
    let companyId =  e.target.value;
    setFormData({ ...formData, vehicleCompanyId: companyId});
    let variantData = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/variant?vehicleCompanyId=' + companyId);
    setVehicleVariant(variantData.data);
    
  }
  return (
    <div>
      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Vehicle Company</StyledTableCell>
              <StyledTableCell>Variant Name</StyledTableCell>
              <StyledTableCell>Accessory Type</StyledTableCell>
              <StyledTableCell>Accessory Name</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>              
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {accessoryData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((accessory) => {
                if(accessory.variantName && accessory.vehicleCompany){
                  return (<StyledTableRow key={accessory._id}>
                    <StyledTableCell>{accessory.vehicleCompany.vehicleCompany}</StyledTableCell>
                    <StyledTableCell>{accessory.variantName.variantName}</StyledTableCell>
                    <StyledTableCell>{accessory.accessoryType}</StyledTableCell>
                    <StyledTableCell>{accessory.accessoryName}</StyledTableCell>
                    <StyledTableCell>{accessory.price}</StyledTableCell>                  
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleEditClick(accessory)}
                      >
                        Edit
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        onClick={() => handleDeleteClick(accessory)}
                      >
                        Delete
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>)
                }                
              })}             
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={accessoryData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Accessory
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>{selectedAccessory ? "Edit Accessory" : "Add New Accessory"}</DialogTitle>
        <br/>
        <DialogContent>
        <FormControl fullWidth>
            <InputLabel id="vechicleCompany">Vehicle Company</InputLabel>
            <Select
                labelId="vechicleCompany"                
                value={formData.vehicleCompanyId}
                fullWidth
                label="Vehicel Company"
                onChange={(e) =>{ onCompanyChange(e) }
                }
            >
              {
                vehicleCompany.map(company => {
                  return (
                    <MenuItem value={company._id}>{company.vehicleCompany}</MenuItem>
                  )
                })
              }
            </Select>
           </FormControl>
          <br/><br/> 
          <FormControl fullWidth>
            <InputLabel id="vehicleVariant">Vehicle Variant</InputLabel>
            <Select
                labelId="vehicleVariant"                
                value={formData.variantNameId}
                fullWidth
                label="Vehicle Variant"
                onChange={(e) =>
                    setFormData({ ...formData, variantNameId: e.target.value })
                }
            >
                {
                vehicleVariant.map(variant => {
                  return (
                    <MenuItem value={variant._id}>{variant.variantName}</MenuItem>
                  )
                })
              }                
            </Select>
           </FormControl> 
           <br/><br/> 
           <FormControl fullWidth>
            <InputLabel id="accessoryType">Accessory Type</InputLabel>
            <Select
                labelId="accessoryType"                
                value={formData.accessoryType}
                fullWidth
                label="Accessory Type"
                onChange={(e) =>
                    setFormData({ ...formData, accessoryType: e.target.value })
                }
            >                
              <MenuItem value="Infotainment">Infotainment</MenuItem>
              <MenuItem value="Exterior">Exterior</MenuItem>
              <MenuItem value="Interior">Interior</MenuItem>
              <MenuItem value="Lighting">Lighting</MenuItem>
              <MenuItem value="Electronics">Electronics</MenuItem>
            </Select>
           </FormControl> 
          <br/><br/>
          <TextField
            label="Accessory Name"
            name="accessoryName"
            value={formData.accessoryName}
            onChange={(e) =>
              setFormData({ ...formData, accessoryName: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Price"
            name="price"
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Accessory;
