import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert
} from "@mui/material";
import { styled } from '@mui/material/styles';

function BusinessList(){
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
    const [businessList, setBusinessList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        // Fetch data from your API and update 'companyData' state   
        axios.get(process.env.REACT_APP_API_URL+'/api/listbusiness/' ).then((response) => setBusinessList(response.data));      
      }, []);

      return (
        <div>
            <TableContainer>
                <Table>           
                    <TableHead>
                        <StyledTableRow>
                        <StyledTableCell>Shop Name</StyledTableCell>  
                        <StyledTableCell>Shop Address</StyledTableCell>
                        <StyledTableCell>Shop Contact</StyledTableCell>
                        <StyledTableCell >Shop Email</StyledTableCell> 
                        <StyledTableCell >GST No</StyledTableCell>              
                        </StyledTableRow>
                    </TableHead>
                    
                    <TableBody>
                        {
                            businessList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((business) => {                                
                                return (
                                    
                                    <StyledTableRow key={business._id} >
                                        <StyledTableCell >{business.shopName}</StyledTableCell> 
                                        <StyledTableCell >{business.shopAddress}</StyledTableCell> 
                                        <StyledTableCell >{business.shopContact}</StyledTableCell> 
                                        <StyledTableCell >{business.shopEmail}</StyledTableCell> 
                                        <StyledTableCell >{business.gstNo}</StyledTableCell> 
                                    </StyledTableRow>
                                    
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={businessList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </div>

      )
}

export default BusinessList