import React,{useState,useEffect} from "react";
import { Route, Routes,useNavigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";

import Login from "./components/Login";
import Home from "./components/Home";
import Tyre from "./components/Tyre";
import Shop from "./components/Shop";
import Battery from "./components/Battery";
import Company from "./components/Company";
import CompanyVariant from "./components/CompanyVariant";
import Logout from "./components/Logout"
import Accessory from "./components/Accessory"
import Enquiry from "./components/Enquiry"
import BusinessList from "./components/businessList";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userData,setUserData] = useState(null);
  const history = useNavigate();
  useEffect(() => {
    var userDataSess = sessionStorage.getItem("userData");
    if(userDataSess != null){
      setUserData(JSON.parse(userDataSess))
      setLoggedIn(true);
    }
      
  },[])
  
  const handleLogin = (data) => {
    // You should implement your authentication logic here
    // For this example, just setting loggedIn to true
    sessionStorage.setItem("userData",JSON.stringify(data));
    setUserData(data);
    setLoggedIn(true);
    
    if(data && data.type == 'superAdmin'){
      history('/shop');   
    }      
    else{
      console.log('shop user')
      history('/battery');   
    }
      
  };

  const handleLogout = () => {    
    sessionStorage.removeItem("userData");
    setLoggedIn(false);
    history('/');
  };

  return (
      <div> 
        <Routes >
            <Route path="/" element={<Login onLogin={handleLogin} />}/>  
        </Routes>         
      {loggedIn && <Sidebar onLogout={handleLogout} userData={userData}/>} 
        <main
          style={{
            display: 'grid',
            padding: '20px', // Add padding for spacing
            overflow: 'auto', // Enable scrolling when content overflows
            marginLeft: '200px'
          }}
        >{
          userData && userData.type && 
          <Routes >             
            <Route path="/logout" element={<Logout handleLogout={handleLogout}/>} />
            {/* <Route path="/home" element={<Home/>} /> */}
            <Route path="/tyre" element={<Tyre userData={userData}/>} />            
            <Route path="/battery" element={<Battery userData={userData}/>} />
            <Route path="/accessories" element={<Accessory userData={userData}/>} />
          </Routes >
        }
          
          {
            userData && userData.type == 'superAdmin' ?
              <Routes>
                <Route path="/shop" element={<Shop userData={userData}/>} />
                <Route path="/company" element={<Company userData={userData}/>} />
                <Route path="/companyVariant" element={<CompanyVariant userData={userData}/>} />
                <Route path="/enquiry" element={<Enquiry userData={userData}/>} />
                <Route path="/businessList" element={<BusinessList userData={userData}/>} />
              </Routes>
            
            : ''}
        </main>
    </div>
    
  );
}

export default App;
