import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { styled } from '@mui/material/styles';

function Tyre(props) {
  console.log([props])
  const [tyreData, setTyreData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openForm, setOpenForm] = useState(false);
  const [selectedTyre, setSelectedTyre] = useState(null);
  const [formData, setFormData] = useState({
    brand: "",
    profile: "",
    price: "",
    season: "",
    efficient: false,
    tubeless: false,
    budgetFriendly: false
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    // Fetch data from your API and update 'tyreData' state
    if(props.userData && props.userData.shopDetails[0])
      var shopId = props.userData?.shopDetails?.[0]._id || '';
    
    if(shopId){
      setFormData({ ...formData, shopId:shopId})
      axios.get(process.env.REACT_APP_API_URL+'/api/tire/filter?shopId=' + shopId).then((response) => setTyreData(response.data));
    }
      
  }, [props.userData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = () => {
    setValidationError(false);
    setSelectedTyre(null);
    setFormData({ brand: "", profile: "", price: "" });
    setOpenForm(true);
  };

  const handleEditClick = (tyre) => {
    setSelectedTyre(tyre);
    setValidationError(false);
    setFormData({
      brand: tyre.brand,
      profile: tyre.profile,
      price: tyre.price,
      season: tyre.season,
      efficient: tyre.efficient,
      tubeless: tyre.tubeless,
      budgetFriendly: tyre.budgetFriendly
    });
    setOpenForm(true);
  };
  const handleDeleteClick = async (tyre) => {
    let id = tyre._id;
    let data = await axios.delete(process.env.REACT_APP_API_URL+'/api/tire/' + id,{
      headers:{
        "Authorization": props.userData.token
      }
    });
    let response = await axios.get(process.env.REACT_APP_API_URL+'/api/tire/filter?shopId=' + formData.shopId);
    setTyreData(response.data); 
  }
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
    try{
      if(formData.brand && formData.profile && formData.price){
        setValidationError(false)
        if(props.userData && props.userData.shopDetails[0])
        formData.shopId = props.userData.shopDetails[0]._id;
        if(!selectedTyre){        
          let data = await axios.post(process.env.REACT_APP_API_URL+'/api/tire',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });        
        }else{
          formData.tyreId = selectedTyre._id;
          let data = await axios.put(process.env.REACT_APP_API_URL+'/api/tire',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });    
        }    
        let response = await axios.get(process.env.REACT_APP_API_URL+'/api/tire/filter?shopId=' + formData.shopId);
        setTyreData(response.data);  
        setOpenForm(false);
      }else{
        setValidationError(true);
      }
            
      
    }catch(e){
      console.log('Error');
    }

    
  };

  return (
    <div>
      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell>Profile</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {tyreData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tyre) => (
                <StyledTableRow key={tyre._id}>
                  <StyledTableCell>{tyre.brand}</StyledTableCell>
                  <StyledTableCell>{tyre.profile}</StyledTableCell>
                  <StyledTableCell>{tyre.price}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditClick(tyre)}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteClick(tyre)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tyreData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Tyre
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>{selectedTyre ? "Edit Tyre" : "Add New Tyre"}</DialogTitle>
        <br/>
        <DialogContent>
          <TextField
            label="Brand"
            name="brand"
            value={formData.brand}
            onChange={(e) =>
              setFormData({ ...formData, brand: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Profile"
            name="profile"
            value={formData.profile}
            onChange={(e) =>
              setFormData({ ...formData, profile: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Price"
            name="price"
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <FormControl fullWidth>
            <InputLabel id="season">Season</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="season"
              value={formData.season}
              label="Season"
              onChange={(e) =>
                setFormData({ ...formData, season: e.target.value })
              }
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="summer">Summer</MenuItem>
              <MenuItem value="winter">Winter</MenuItem>
          </Select>
          </FormControl>
          <br/><br/>
          <FormControlLabel
            label = 'Tubeless'
            control={
            <Checkbox
              checked={formData.tubeless}
              onChange={(e) =>
                setFormData({ ...formData, tubeless: e.target.checked })
              }
              inputProps={{ 'aria-label': 'Tubeless' }}
            />}
          />
          <br/><br/>
          <FormControlLabel
            label = 'Budget Friendly'
            control={
            <Checkbox
              checked={formData.budgetFriendly}
              onChange={(e) =>
                setFormData({ ...formData, budgetFriendly: e.target.checked })
              }
              inputProps={{ 'aria-label': 'Budget Friendly' }}
            />}
          />
          <br/><br/>
          <FormControlLabel
            label = 'Efficient'
            control={
              <Checkbox
                checked={formData.efficient}
                onChange={(e) =>
                  setFormData({ ...formData, efficient: e.target.checked })
                }
              inputProps={{ 'aria-label': 'Efficient' }}
            />
          }
            />
          <br/><br/>
          
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Tyre;
