import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


function Shop(props) {
  const [shopData, setShopData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openForm, setOpenForm] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);
  const [userError,setUserError] = useState(false);
  const [image, setImage] = useState("")
  const [formData, setFormData] = useState({
    "name": "",
    "address":{
        "line1": "",
        "line2": "",
        "city": "",
        "pinCode": "",
    },    
    "contactNo": "",
    "emailId": "",
    "userName": "",
    "password":"",
    "image": ""
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const ImageInput = styled('div')({
    "margin-bottom": '32px',
    "position": 'relative',
    "align-self": 'center',
    "img": {
      "width": "100%",
      "height": "auto",
      "object-fit": "cover"
    }
  })
    
  useEffect(() => {
    // Fetch data from your API and update 'tyreData' state   
    getShops();
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getShops = async () => {
    axios.get(process.env.REACT_APP_API_URL+'/api/shop').then((response) => setShopData(response.data));
  }
  const handleAddClick = () => {
    setSelectedShop(null);
    setValidationError(false);
    setFormData({
        "name": "",
        "address":{
            "line1": "",
            "line2": "",
            "city": "",
            "pinCode": "",
        },    
        "contactNo": "",
        "emailId": "",
        "userName": "",
        "password":"",
        "image": ""       
    });
    setImage("")
    setOpenForm(true);
  };

  const handleEditClick = (shop) => {
    setSelectedShop(shop);
    setValidationError(false);
    setFormData({
        "name": shop.name,
        "address":{
            "line1": shop.address.line1,
            "line2": shop.address.line2,
            "city": shop.address.city,
            "pinCode": shop.address.pinCode,
        },    
        "contactNo": shop.contactNo,
        "emailId": shop.emailId,
        "userName": shop.userName,
        "password":shop.password,
        "image": shop.image      
    });
    setImage(process.env.REACT_APP_BASE_IMAGE_PATH + shop.image)
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
    var arr = Object.values(formData);
    arr.push(Object.values(formData.address))
    var fieldsValid = arr.every(value => {      
      if(!value)        
        return false
      return true      
    });
    if(fieldsValid){      
      if(!selectedShop){
        try{
          var userCreated = await axios.post(process.env.REACT_APP_API_URL+'/api/user',{
              userName: formData.userName,
              password: formData.password,
              type: 'Shop'
          });
        }catch(e){
            setUserError(true);
            console.log('Error while creating user ' + e);
        }
        if(userCreated && userCreated.data){
            try{ 
                const formDataPayload = new FormData();  
                formDataPayload.append('image', formData.image);
                formDataPayload.append('name', formData.name);
                formDataPayload.append('address', JSON.stringify(formData.address));
                formDataPayload.append('contactNo', formData.contactNo);
                formDataPayload.append('emailId', formData.emailId);      
                formDataPayload.append('userName', formData.userName); 
                formDataPayload.append('password', formData.password);                
                let res = await axios.post(process.env.REACT_APP_API_URL+'/api/shop',formDataPayload,{
                    headers:{
                        "Authorization": props.userData.token,
                        'Content-Type': 'multipart/form-data'
                    }
                });
                getShops();
                setUserError(false);
                
            }catch(e){
              console.log('Error while creating shop ' + e);
            }
        }
      }else{
        const formDataPayload = new FormData();  
        if(selectedShop.image != image){          
          formDataPayload.append('image', formData.image);
          formDataPayload.append('oldFile', selectedShop.image);
        }
          
        formDataPayload.append('name', formData.name);
        formDataPayload.append('address', JSON.stringify(formData.address));
        formDataPayload.append('contactNo', formData.contactNo);
        formDataPayload.append('emailId', formData.emailId);
        formDataPayload.append('shopId', selectedShop._id);
        let res = await axios.put(process.env.REACT_APP_API_URL+'/api/shop',formDataPayload,{
          headers:{
              "Authorization": props.userData.token,
              'Content-Type': 'multipart/form-data'
          }
        });
        getShops();
      }
      setOpenForm(false);
      setValidationError(false);
    }else{
      setValidationError(true);
    }
    
  };

  const handleFileChange = (e) => {
    if(e.target.files.length < 1){
      return;
    }
    const file = e.target.files[0];
    var fileType = file.type.toLowerCase();
    var fileSize = file.size/1000;
    if(fileType != 'image/png' && fileType != 'image/jpg' && fileType != 'image/jpeg'){     
      alert('Invalid file type');
      return
    }
    if(fileSize > 100){      
      alert('File size should be less than 100kb');
      return
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
      setFormData({ ...formData, image: e.target.files[0]})
    }    
    
  }

  const handleClose = () => {
    setUserError(false);
  };

  return (
    <div>      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Contact</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell> 
              <StyledTableCell>UserName</StyledTableCell> 
              <StyledTableCell>Password</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>              
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {shopData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((shop) => (
                <StyledTableRow key={shop._id}>
                  <StyledTableCell>{shop.name}</StyledTableCell>
                  <StyledTableCell>{shop.contactNo}</StyledTableCell>
                  <StyledTableCell>{shop.emailId}</StyledTableCell>
                  <StyledTableCell>{shop.userName}</StyledTableCell>
                  <StyledTableCell>{shop.password}</StyledTableCell>
                  { <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditClick(shop)}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={shopData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Shop
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>{selectedShop ? "Edit Shop" : "Add New Shop"}</DialogTitle>
        <br/>
        <DialogContent>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={(e) =>
              setFormData({ ...formData, name: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Address Line 1"
            name="line1"
            value={formData.address.line1}
            onChange={(e) =>{
                let address = formData.address;
                address.line1 = e.target.value;
                setFormData({ ...formData, address: address})
            }}
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Address Line 2"
            name="line2"
            value={formData.address.line2}
            onChange={(e) => {
                let address = formData.address;
                address.line2 = e.target.value;
                setFormData({ ...formData, address: address})
            }}
            fullWidth
          />
          <br/><br/>
          <FormControl fullWidth>
            <InputLabel id="City">City</InputLabel>
            <Select
                labelId="City"                
                value={formData.address.city}
                fullWidth
                label="City"
                onChange={(e) =>{
                  let address = formData.address;
                  address.city = e.target.value;
                  setFormData({ ...formData, address: address})
              }}              
            >
                <MenuItem value="Mumbai">Mumbai</MenuItem>
                <MenuItem value="Pune">Pune</MenuItem>
                
            </Select>
           </FormControl>
          
          <br/><br/>
          <TextField
            label="Pin Code"
            name="pinCode"
            value={formData.address.pinCode}
            onChange={(e) =>{
                    let address = formData.address;
                    address.pinCode = e.target.value;
                    setFormData({ ...formData, address: address})
            }}
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Contact No"
            name="contactNo"
            value={formData.contactNo}
            onChange={(e) =>
              setFormData({ ...formData, contactNo: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Email Address"
            name="emailId"
            value={formData.emailId}
            onChange={(e) =>
              setFormData({ ...formData, emailId: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="UserName"
            name="userName"
            value={formData.userName}
            disabled={selectedShop ? true : false}
            onChange={(e) =>
              setFormData({ ...formData, userName: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Password"
            name="password"
            value={formData.password}
            disabled={selectedShop ? true : false}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            fullWidth
          />
           <br/><br/>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload Image
          <VisuallyHiddenInput type="file" accept="image/*" onChange={(e)=>{
            handleFileChange(e)
          }}/>
          </Button>
          <br/><br/>
          <ImageInput>
            {image ? <img src={image} /> : <div/>}
          </ImageInput>
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
        {userError ? <Alert severity="error">Failed to create user. Please try different user name</Alert> : ''}
      </Dialog>
    </div>
  );
}

export default Shop;
