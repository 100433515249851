import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Alert
} from "@mui/material";
import { styled } from '@mui/material/styles';

function CompanyVariant(props) {
  console.log([props])
  const [variantData, setVariantData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState({vehicleCompanyId: "",variantName:"" });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    // Fetch data from your API and update 'variantData' state   
    axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/variant').then((response) => setVariantData(response.data));
    axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/companies').then((response) => setCompanyData(response.data));      
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = () => {  
    setValidationError(false);  
    setFormData({vehicleCompanyId: "",variantName:"" });
    setOpenForm(true);
  };

  
  const handleDeleteClick = async (variant) => {
    let id = variant._id;
    let data = await axios.delete(process.env.REACT_APP_API_URL+'/api/vehicle/variant/' + id,{
      headers:{
        "Authorization": props.userData.token
      }
    });
    let response = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/variant');
    setVariantData(response.data); 
  }
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
    try
    {
      if(formData.vehicleCompanyId && formData.variantName){
        setValidationError(false);
        let data = await axios.post(process.env.REACT_APP_API_URL+'/api/vehicle/variant',{...formData},{
          headers:{
            "Authorization": props.userData.token
          }
        });   
        let response = await axios.get(process.env.REACT_APP_API_URL+'/api/vehicle/variant');
        setVariantData(response.data);  
        setOpenForm(false);
      }else{
        setValidationError(true);
      }             
    }
    catch(e){
      console.log('Error');
    }
  }
  return (
    <div>
      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Variant Name</StyledTableCell> 
              <StyledTableCell>Delete</StyledTableCell>              
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {variantData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((variant) => {
                if(variant.vehicleCompany){
                  return (
                    <StyledTableRow key={variant._id}>                  
                      <StyledTableCell>{variant.vehicleCompany.vehicleCompany}</StyledTableCell>
                      <StyledTableCell>{variant.variantName}</StyledTableCell> 
                      <StyledTableCell>
                        <Button
                          variant="outlined"
                          onClick={() => handleDeleteClick(variant)}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={variantData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Variant
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>Add New variant</DialogTitle>
        <br/>
        <DialogContent>
        <FormControl fullWidth>
            <InputLabel id="companyName">Company Name</InputLabel>
            <Select
                labelId="companyName"                
                value={formData.vehicleCompanyId}
                fullWidth
                label="Company Name"
                onChange={(e) =>
                    setFormData({ ...formData, vehicleCompanyId: e.target.value })
                }
            >
                {companyData.map((company) => {
                  return (
                    <MenuItem value={company._id}>{company.vehicleCompany}</MenuItem>
                  )
                })}
                
            </Select>
           </FormControl> 
           <br/><br/>
          <TextField
            label="Variant Name"
            name="variantName"
            value={formData.variantName}
            onChange={(e) =>
              setFormData({ ...formData, variantName: e.target.value })
            }
            fullWidth
          />          
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CompanyVariant;
