import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Paper,
  Container,
  Avatar,
  Alert
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios"

function Login(props) {
  const [formData, setFormData] = useState({ userName: "", password: "" });
  const [authError, setAuthError] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Perform API authentication here using axios
      // Replace the API call with your actual authentication logic
      const response = await axios.post(process.env.REACT_APP_API_URL + "/api/authenticate", formData);     
      setAuthError(false)
      props.onLogin(response.data);
      
    } catch (error) {
      setAuthError(true)
      console.error("API error:", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Username"
            name="userName"
            autoComplete="username"
            autoFocus
            value={formData.userName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: "1rem 0" }}
          >
            Sign In
          </Button>
          {authError ? <Alert severity="error">User name or Password invalid</Alert> : ''}
        </form>
      </Paper>
    </Container>
  );
}

export default Login;
