import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material";
import { styled } from '@mui/material/styles';

function Battery(props) {
  console.log([props])
  const [batteryData, setBatteryData] = useState([]);
  const [validationError, setValidationError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openForm, setOpenForm] = useState(false);
  const [selectedBattery, setSelectedBattery] = useState(null);
  const [formData, setFormData] = useState({
    fuelType: "",
    brand: "",
    price: "",    
    modelNo: "",
    layout: "",
    capacity: ""
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  useEffect(() => {
    // Fetch data from your API and update 'batteryData' state
    if(props.userData && props.userData.shopDetails[0])
      var shopId = props.userData?.shopDetails?.[0]._id || '';
    if(shopId){
      setFormData({ ...formData, shopId:shopId})
      axios.get(process.env.REACT_APP_API_URL+'/api/battery/filter?shopId=' + shopId).then((response) => setBatteryData(response.data));
    }
      
  }, [props.userData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddClick = () => {
    setSelectedBattery(null);
    setValidationError(false);
    setFormData({
        fuelType: "",
        brand: "",
        price: "",    
        modelNo: "",
        layout: "",
        capacity: ""
      });
    setOpenForm(true);
  };

  const handleEditClick = (battery) => {
    setSelectedBattery(battery);
    setValidationError(false);
    setFormData({
        fuelType: battery.fuelType,
        brand: battery.brand,
        price: battery.price,    
        modelNo: battery.modelNo,
        layout: battery.layout,
        capacity: battery.capacity
      });
    setOpenForm(true);
  };
  const handleDeleteClick = async (battery) => {
    let id = battery._id;
    let data = await axios.delete(process.env.REACT_APP_API_URL+'/api/battery/' + id,{
      headers:{
        "Authorization": props.userData.token
      }
    });
    let response = await axios.get(process.env.REACT_APP_API_URL+'/api/battery/filter?shopId=' + formData.shopId);
    setBatteryData(response.data); 
  }
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSave = async () => {
    // Handle save logic here (add or update data in your API)
    // After successful save, update the local state and close the form
    try{
      formData.shopId = props.userData?.shopDetails?.[0]._id || '';
      if(formData.fuelType && formData.brand && formData.price && formData.modelNo && formData.layout && formData.capacity){
        setValidationError(false);
        if(!selectedBattery){        
          let data = await axios.post(process.env.REACT_APP_API_URL+'/api/battery',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });        
        }else{
          formData.batteryId = selectedBattery._id;
          let data = await axios.put(process.env.REACT_APP_API_URL+'/api/battery',{...formData},{
            headers:{
              "Authorization": props.userData.token
            }
          });    
        }    
        let response = await axios.get(process.env.REACT_APP_API_URL+'/api/battery/filter?shopId=' + formData.shopId);
        setBatteryData(response.data);  
        setOpenForm(false);   
      }else{
        setValidationError(true);
      }
         
      
    }catch(e){
      console.log('Error');
    }

    
  };

  return (
    <div>
      
      <TableContainer>
        <Table>
          {/* Table Header */}
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell>Fuel Type</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell>Model No</StyledTableCell>
              <StyledTableCell>Layout</StyledTableCell>
              <StyledTableCell>Capacity</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {batteryData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((battery) => (
                <StyledTableRow key={battery._id}>
                  <StyledTableCell>{battery.brand}</StyledTableCell>
                  <StyledTableCell>{battery.fuelType}</StyledTableCell>
                  <StyledTableCell>{battery.price}</StyledTableCell>
                  <StyledTableCell>{battery.modelNo}</StyledTableCell>
                  <StyledTableCell>{battery.layout}</StyledTableCell>
                  <StyledTableCell>{battery.capacity}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleEditClick(battery)}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteClick(battery)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={batteryData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
      <Button variant="contained" color="primary" onClick={handleAddClick}>
        Add New Battery
      </Button>
      <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
        <DialogTitle>{selectedBattery ? "Edit Battery" : "Add New Battery"}</DialogTitle>
        <br/>
        <DialogContent>
          <TextField
            label="Brand"
            name="brand"
            value={formData.brand}
            onChange={(e) =>
              setFormData({ ...formData, brand: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <FormControl fullWidth>
            <InputLabel id="fuelType">FuelType</InputLabel>
            <Select
                labelId="fuelType"                
                value={formData.fuelType}
                fullWidth
                label="Fuel Type"
                onChange={(e) =>
                    setFormData({ ...formData, fuelType: e.target.value })
                }
            >
                <MenuItem value="Petrol">Petrol</MenuItem>
                <MenuItem value="Diesel">Diesel</MenuItem>
                
            </Select>
           </FormControl> 
          <br/><br/>
          <TextField
            label="Price"
            name="price"
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Model No"
            name="modelNo"
            value={formData.modelNo}
            onChange={(e) =>
              setFormData({ ...formData, modelNo: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Layout"
            name="layout"
            value={formData.layout}
            onChange={(e) =>
              setFormData({ ...formData, layout: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
          <TextField
            label="Capacity"
            name="capacity"
            value={formData.capacity}
            onChange={(e) =>
              setFormData({ ...formData, capacity: e.target.value })
            }
            fullWidth
          />
          <br/><br/>
        </DialogContent>
        {
          validationError ? <Alert severity="error">All fields are mandatory</Alert>: ''
        }
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Battery;
