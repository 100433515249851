import React from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AccessoriesIcon from "@mui/icons-material/Build"; // Import an accessory icon
import LogoutIcon from '@mui/icons-material/Logout';
const sidebarStyle = {
  width: '250px', // Set a fixed width for the sidebar
  position: 'fixed', // Fixed positioning to keep it on the left
  top: 0, // Position it at the top
  left: 0, // Position it at the left
  bottom: 0, // Stretch it to the bottom
};

const buttonStyle = {
  marginTop: 'auto', // Push the Logout button to the bottom
};

function Sidebar(props) {
  console.log(props)
  return (
    <Drawer variant="permanent" style={sidebarStyle}>
      <List>       
        {
            props.userData && props.userData.type == 'superAdmin' ? 
            <div>
              <ListItem component={Link} to="/shop">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Shops" />
              </ListItem>
              <ListItem component={Link} to="/businessList">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Business List" />
              </ListItem>
              <ListItem component={Link} to="/company">
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Vehicle Company" />
              </ListItem>
              <ListItem component={Link} to="/companyVariant">
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Vehicel Model" />
              </ListItem> 
              <ListItem component={Link} to="/enquiry">
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Enquiries" />
              </ListItem>              
            </div>
            : 
            <div> 
              <ListItem component={Link} to="/battery">
                <ListItemIcon>
                  <BatteryFullIcon />
                </ListItemIcon>
                <ListItemText primary="Battery" />
              </ListItem>
              <ListItem component={Link} to="/tyre">
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary="Tyre" />
              </ListItem>
              <ListItem component={Link} to="/accessories">
                <ListItemIcon>
                  <AccessoriesIcon />
                </ListItemIcon>
                <ListItemText primary="Accessories" />
              </ListItem>
            </div>
        }
        <ListItem component={Link} to="/logout">
          <ListItemIcon>
          <LogoutIcon />
            </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
      </List>
    </Drawer>
  );
}

export default Sidebar;
